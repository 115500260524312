import React from 'react';
import './ComingSoon.css';
import logo from './assets/cleo.gif'

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
        <div className="banner">
            <img src={logo} alt="" />
        </div>
      <header className="header">
        <h1>💳 COMING SOON 💳</h1>
      </header>
      <main className="content">
        <h2>We're building something amazing.</h2>
        <p>Stay tuned for updates!</p>
        
        <span><b>Tox:</b> 1C652CB9DC1B0FF68AC5DCC5C18C6CA1DEE4F06C361230107A9491F14C508E22A43F1E27A399</span>
        
      </main>
      <footer className="footer">
      </footer>
    </div>
  );
}

export default ComingSoon;